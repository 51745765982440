<template>
  <div class="my-2 px-3">
    <div
      class="mx-auto flex h-9 max-w-md items-center gap-3 rounded-xl bg-white px-3 shadow dark:bg-neutral-800"
    >
      <div class="relative h-1.5 w-full overflow-hidden rounded bg-gray-200">
        <div
          class="absolute h-full bg-primary transition-all duration-500"
          :style="{ width: `${giftList.progressPercent}%` }"
        ></div>
      </div>

      <div v-if="cartStore.nextGiftLevel" class="whitespace-nowrap">
        {{ money(cartStore.nextGiftLevel.requiredSum) }}
      </div>

      <div class="relative shrink-0">
        <img
          src="/images/gift.svg"
          class="h-6 transition-all duration-500"
          alt="Подарок"
          :class="{
            grayscale: giftList.currentLevel < 1,
            'scale-125': giftList.currentLevel >= 1,
          }"
        />
        <transition
          enter-active-class="transition duration-500"
          enter-from-class="opacity-0 scale-75"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition duration-500"
          leave-from-class="opacity-100 scale-75"
          leave-to-class="opacity-0 scale-50"
        >
          <div
            v-if="giftList.currentLevel > 0"
            class="absolute -right-2 -top-2 grid h-5 w-5 place-items-center rounded-full bg-gray-900 text-sm text-white"
          >
            {{ giftList.currentLevel }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GiftList } from '~/types/cart'

defineProps<{
  giftList: GiftList
}>()

const cartStore = useCartStore()
</script>
